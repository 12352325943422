body {
  margin: 0;
}

.word-order-text {
  font-family: Arial, Helvetica, sans-serif;
  width: 107px;
  font-size: 13px;
  color: #464032;
  padding: 0;
  margin: 0 0 0 5px;
  font-style: italic;
  display: flex;
  align-items: center;  
}

.word-order-icon {
  height: 22px;
  cursor: pointer;
}

#label1 {
    font-size: 13px;
    color: #A42903;
    font-weight: bold;
}

#label1 span {
  font-weight: normal;
}

#label0 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
}

h2 {
  font-size: 2.4em;
}