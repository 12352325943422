#heraldique-sheet-container  {
    background-color: #FFF;
    width: 60%;
    margin-bottom: 60px;
    padding: 0 10px 20px 10px;
    margin-top: 80px;
}

#heraldique-sheet-container h3 {
    font-weight: normal;
    color: #A42903;
    font-size: 1.6em;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    width: 100%;
}

.results-heraldique-container-sheet {
    display: grid;
    grid-template-columns: max-content fit-content(100%);
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 0px; 
}