#book-back {
    background-image: url('../images/MAT-Grande-salle-petite-moyenne.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.bookform-container {
    background-image: url('../images/Troyes-AD-043=Troyes-20.jpg');  
    background-repeat: repeat;
    background-position: center center;
    opacity: 0.8;
    width: 1000px;
    min-height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    padding-top: 200px;
    box-shadow: 1px 1px 12px #555;
    margin-bottom: 100px;  
    z-index: 2;
}

.book-container-admin {
    align-items: normal;
}

.bookform-container #label1 {
    font-size: 17px;
    font-family: Garamond;
}

#book-title {
    height: 110px;
    top: 50px;
    position: absolute;
    z-index: 1;
}

#book-footer {
    position: absolute;
    bottom: 5px;
}

.book-navbar-link {
    text-decoration: none;
    font-weight: bold;
    color: #464032;
    font-size: 14px;
    margin-right: 30px;
    vertical-align: middle;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

.book-navbar-link img {
    width: 250px;
}

#book-header {
    width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 90px;
}

#book-header a {
    text-decoration: none;
}

.book-list-item {
    list-style: none;
}

#book-patronyme {
    color: #A42903;
    font-weight: bold;
}

.results-data-books {
    font-family: Garamond;
    font-size: 17px;
    font-weight: bold;
    color: black;
}

.show-book {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}    

.loading-img-book {
    margin-left: 0;
}

.admin-book-cancel-button {
    margin-top: 10px;
}