#heraldique-back {
    background-image: url(../images/ToisonOr.png);
    background-color: #FFFDEA;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top right;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
}

#aube {
    height: 135px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.heraldique-navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 948px;
    height: 36px;
    border-radius: 5px;
    background-color: #ad9a7a;
    margin-top: 125px;
}

.heraldique-navbar a {
    text-decoration: none;
}

.heraldique-navbar-text {
    display: block;
    height: 36px;
    padding: 0 30px 0 30px;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    color: #FFFFFF;
    border: none;
    text-transform: uppercase;
    line-height: 36px;
    cursor: pointer;
}

.heraldique-navbar-text:hover {
    background: #E0D3B3;
}

.public-heraldique, .private-heraldique {
    width: 60%;
    margin-bottom: 60px;
}

.private-heraldique fieldset{
    background-color: rgb(243, 114, 49);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px
}

#private-request-heraldique {
    position: relative;
    right: 34.8%;;
    margin-left: 2%;
    margin-top: 15px;
}

.public-heraldique fieldset {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px
}

#heraldique-input .word-order-icon {
    margin-left: 5px;
}

#heraldique-input .word-order-text, #heraldique-input .word-order-icon {
    margin-top: 16px;
}

#heraldique-bottom-grid {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 0;
    grid-row-gap: 0;
    justify-items: center;
    width: fit-content;
    margin-bottom: 25px;
    width: auto;
}

#heraldique-bottom-grid-left , #heraldique-bottom-grid-right {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 0 16px;
}

#heraldique-bottom-grid-left div , #heraldique-bottom-grid-right div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#heraldique-bottom-grid-left div input, #heraldique-bottom-grid-right div input , #heraldique-bottom-grid-left div select, #heraldique-bottom-grid-right div select , #heraldique-bottom-grid-left div label, #heraldique-bottom-grid-right div label {
    margin-top: 16px;
}

#label10 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
    font-weight: bold;
}

#heraldique-button {
    margin-bottom: 20px;
}

.results-heraldique {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #1314fb;
    margin: 0;
}

.results-heraldique-container {
    margin-left: -40px !important;
    display: grid;
    grid-template-columns: repeat(2, fit-content(100%));
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 0px; 
}

#heraldique-private-button {
    margin-top: 40px;
    width: fit-content;
    position: relative;
    right: 45%;
    margin-left: 2%;
}

.answer-results-heraldique {
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
    text-align: right;
    margin: 10px 40px 10px 0;
    left: 40%;
    margin-right: 2%;
}

.area-form {
    list-style: none;
    padding: 0;
}

.area-form *  {
    list-style: none;
}

.area-show {
    display: block;
}

.area-hidden {
    display: none;
}

.ref-list {
    display: flex;
    flex-direction: row;
    justify-content: top;
    margin-bottom: 8px;
}

.ref-list input[type="checkbox"] {
    height: fit-content;
}