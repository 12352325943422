#armorial-back {
    background-image: url(../images/Brienne-le-Chateau010=Origny.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top right;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#armorial-title {
    margin-top: 30px;
    margin-bottom: 30px;
    color: rgb(228, 26, 26);
    font-size: 2.4em;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
}

.armorial-navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 948px;
    height: 36px;
    border: 2px solid rgb(228, 26, 26);
    border-radius: 5px;
    box-shadow: 1px 1px 12px #555;
}

.armorial-navbar a {
    text-decoration: none;
}

.armorial-navbar-text {
    display: block;
    height: 36px;
    padding: 0 30px 0 30px;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    color: #FFFFFF;
    border: none;
    text-transform: uppercase;
    line-height: 36px;
    cursor: pointer;
}

.armorial-navbar-text:hover {
    background: #E0D3B3;
}