.show-armes {
    display: grid;
    grid-template-columns: 1fr 1fr;    
}

.show-iconos {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin: 10px 80px;
}

.show div {
    padding: 7px 0;
    width: fit-content;
}

.show div ul {
    padding-left: 90px
}

.return {
    text-decoration: none;
    color: #A7430F;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;  
    margin: 15px 0 0 20px  
}

.return h4 {
    font-weight: lighter;
}

.results-data-armes {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
}

.results-armes {
    font-weight: bold;
}

.results-iconos{
    font-weight: bold;
    color: #464032;
    font-size: 13px;
}

.sheet-images-container {
    display: flex;
    justify-content: center;
}

#go-to-arme {
    text-align: center;
}