#icono-armes-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}
#icono-armes-input input{
    width: 300px;
}

#icono-armes-input p, #icono-armes-input img{    
    margin-top: 16px;
}

select {
    width: 250px;
}

#armes-bottom-grid-right button {
    margin-top: 16px;
    width: fit-content;
    margin-left: 50%;
    transform: translateX(-50%);
}

.results-iconos-details {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
}

.results-iconos {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
    font-weight: bold;
    margin-left: 40px;
}

.results-container-icono {
    position: relative;
    height: 120px;
}

.patronyme-icono{   
    padding-top: 45px;
}

.atachement-img-icono {
    position: absolute;
    right: 2.15%;
    top: 0;
}