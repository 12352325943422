#home-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    justify-content: center;
    align-items: center;      
    font-family: Gabriola;
}

.home-back-img {
    height: 50vh;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-back-img img {
    position: absolute;
    width: 50vw;
    height: 50vh;
    z-index: 1;
    opacity: 1;
}

.home-back-img:hover img {
    opacity: 0.3;
}

.home-back-text {    
    position: absolute;
    transform: translate(-50%, -50%);
    width: 40vw;
    max-height: 50vh;    
    background-color: black;
    color: white;
    font-size: 3vmin;
    padding: 1vw 1vw;
    display: block;
    text-decoration: none;
    opacity: 0;
    z-index: 2;
}

.home-back-img:hover .home-back-text {
    opacity: 0.8 ;
}

#home-middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

#home-middle img {
    width: 256px;
}

#home-middle p {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 140%;
    cursor: pointer;
    color: #fff;
    text-align: center;
    background-color: #e79e61;
    height: 30px;
    width: 150px;
    margin: 0;
}

#home-middle p:hover {
    background-color: #9b683f;
}

#home-middle a {
    text-decoration: none;
}

#home-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    height: fit-content;
}

#home-footer-img-aibl {
    width: 192px;
    margin-right: 20px;
}

#home-footer-img-isep {
    width: 172px;
}