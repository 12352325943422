#bibli-back {
    margin: 0;
    padding: 0;
    background: #FFFDEA;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    padding: 30px;
}

#bibli-back form {
    text-align: left;
}

#bibli-back h1 {
    margin: 15.21px;
}

.bibli-title {
    margin-top: 17.21px;
}

#bibli-back img {
    height: 400px;
}
.bibli-title-tomes, #bibli-back a {
    font-size: 13px;
    margin: 5px;
    padding: 0;
    color: #A42903;
    font-style: italic;
}

.bibli-title-tomes {
    font-weight: lighter;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    font-style: normal;
    text-decoration: underline;
    text-decoration-color: #A42903;
    text-decoration-thickness: 0.5px;
}

.bibli-desc {
    margin-top: 40px;
    margin-bottom: 15px;
    padding: 0;
    color: #A42903;
    font-style: italic;
}

.navbar-biblio {
    color: #464032 !important;
    font-style: normal;
    width: 100px;
}

.navbar-biblio-container {
    margin-bottom: 20px;
    width: 948px !important;
}


.bibli-button {
    width: 250px;
    padding: 10px;
    height: fit-content;
}

.bibli-button a {
    text-decoration: none;
    font-style: normal !important;
    font-size: 20px !important;
}

#bibli-buttons-container {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 40px;   
}
