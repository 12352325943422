#book-submit {
    position: absolute;
    padding-top: 100px;
    padding-bottom: 100px;
    width: 60px;
    right: 0px;
    top: 0px;
    border: 2px solid #A7430F;
    border-radius: 5px;
    box-shadow: 2px 0px #A7430F;
    font-family: Garamond;
    font-size: 200%;
    font-weight: bold;
    color: #A7430F;
    cursor: pointer;
    background-color: transparent;
    writing-mode: vertical-rl;
    text-orientation: upright;
}

.book-form  {
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dictionnary-icon {
    margin-top: 16px;
    margin-right: 5px;
    height: 22px;
    cursor: pointer;
    margin-left: -27px;
}

#armes-select-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin-top: 10px;
}

.armes-select-grid-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.armes-select-grid-item span {
    margin: 0;
    margin-top: 10px;
}

.armes-select-grid-item img {
    max-height: 100px;
}

.patronyme-book {
    padding-top: 20px;
}
