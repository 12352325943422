#armesfamilles-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin: 20px auto;
    width: 74%;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: 1px 1px 12px #555;
    padding: 0 10px 20px 10px;
}

.private-container {
    background-color: rgb(243, 114, 49);
}

.public-container {
    background-color: #fffdea;
}

#armesfamilles-container h3 {
    font-weight: normal;
    color: #A42903;
    font-size: 1.6em;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    width: 100%;
}

legend {
    color: #464032;
    font-size: 13px;
    font-weight: bold;
}

label {
    display: inline-block;
    width: 130px;
    text-align: left;
}

input {
    margin-right: 5px;
}

#armes-form {
    width: 96%;
    position: relative;    
    left: 50%;
    transform: translateX(-50%);
}

#armes-form fieldset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#armes-armes-input {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    margin-left: 32px;
}

#armes-armes-input input {
    width: 438px;
    height: 16px;
}
#armes-bottom-grid {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-items: left;
    width: 100%;
    height: fit-content;
    margin-bottom: 25px;
}

#armes-bottom-grid-left , #armes-bottom-grid-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 16px;
}

#armes-bottom-grid-left div , #armes-bottom-grid-right div {
    display: flex;
    flex-direction: row;
}

#armes-bottom-grid-left div input, #armes-bottom-grid-right div input , #armes-bottom-grid-left div select, #armes-bottom-grid-right div select , #armes-bottom-grid-left div label, #armes-bottom-grid-right div label {
    margin-top: 16px;
}

#private-button {
    margin-top: 40px;
    width: fit-content;
    margin-left: 2%;
}

#private-request {
    margin-left: 2%;
    margin-top: 15px;
}

#private-pwd-state {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
}

.img-modal-back {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    top: 0;
    left: 0;    
}

.img-modal {
    position: absolute;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background-color: #fff !important;
    padding: 20px;
}

.img-modal-img {
    height: 600px;
}

.cross-icon {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px !important;
    cursor: pointer;
}

.left-arrow {
    position: absolute;
    z-index: 99;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    cursor: pointer;
}

.right-arrow {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    cursor: pointer;
}

.atachement-img {
    cursor: pointer;
    height: 100px;
    padding: 5px
}

.head-results {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.answer-container {
    margin-top: 15px;
    width: 100%;
}

.answer-results {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
    text-align: right;
    margin: 10px 40px 10px 0;
}

.results-container ul {
    margin: 0;
    padding-right: 40px;
}

.results-container div {
    margin: 0;
    padding: 0 40px;
}

.patronyme {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
    border-top: 1px solid #cfc0c0;
    font-style: italic;
}

.patronyme a {
    color: #A7430F;
    text-decoration: none;
    font-weight: bold;
    font-style: normal; 
}

.patronyme::marker, .armes-list li::marker {
    color: #464032;
}

.armes-list {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #269223;
    margin: 0;
    list-style-position: inside;
    padding-left: 60px;
}   

.results-armes{
    font-weight: bold;
    color: #464032;
    font-size: 13px;
    margin-left: 40px;
    width: fit-content;
}

.armes-list::marker {
    color: #269223;
}

.loading-img {
    width: 150px;
    height: auto;
    margin-left: calc(50% - 16px);
    margin-top: 20px;
}

.armes-reset-button {
    margin-top: 15px;
}

.blason-list {
    padding: 0;
}