#connexion-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40vh;
}

#connexion-logo {
    height: 35px;
    border: none;
    margin-bottom: 50px;
    margin-top: 5vh;
}

.connexion-label {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
    width: 90px;
}

#connexion-legend {
    color: #A42903;
}

#lost-password {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    text-decoration: none;
    color: #A7430F;
}

#connexion-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
}

#connexion-form button {
    margin-top: 10px;
}

.deconnexion {
    position: absolute;
    top: 20px;
    right: 20px;
}