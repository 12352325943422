.admin-title {
    font-size: 1.6em;
    font-weight: normal;
    color: #A42903;
    text-align: center;
}

.admin-container {
    display: flex;
    flex-direction: column;
    width: 56%;
}

.onglets {
    display: flex;
    justify-content: left;
}

.onglet-active  {
    display: inline-block;
    margin-right: 6px;
    padding: 3px;
    border: 1px solid black;
    cursor: pointer;
    background-color: #e2e4e5;
    border-bottom: 0px solid black;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
}

.onglet-inactive {
    background-color: #bbbbbb;
    border: 1px solid black;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: inline-block;
    margin-right: 6px;
    padding: 3px;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
}

.admin-functions-container {
    background-color: #e2e4e5;
    padding: 10px;
    border-radius: 0 20px 20px 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.admin-legend {
    color: #A42903;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
}

.admin-form fieldset{
    background-color: #FFF;
}

.admin-label {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
    width: 190px;
}

.actual-mail {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
    font-weight: bold;
}

.admin-form fieldset button {
    margin-right: 20px;
}

.admin-form-space {
    margin-top: 40px;
}

.admin-help-img {
    cursor: help;
    height: 16px !important ;
    width: 16px !important;
}

.admin-form fieldset legend{
    position: relative;
}

.admin-form fieldset legend:hover div {
    display: block;
    border: 1px solid #008000;
    border-left: 4px solid #008000;
    background-color: #ffefb6;
    color: black;
    font-weight: normal;
    padding: 3px;
    position: absolute;
    width: max-content;
    left: 100%;
    z-index: 3;
}

.admin-form fieldset legend div {
    display: none;
}

#reset-count-button {
    margin-top: 20px;
}

.admin-search-results-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.admin-search-modify-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px;
}

.admin-search-results-title {
    color: #A7430F;
}

.admin-modify-button {
    width: fit-content;
}

.admin-form-table fieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-form-table table {
    border-spacing: 0;
    width: 100%;
    border: 1px solid #222222;
    font-size: 13px;
    color: #464032;
}

.admin-form-table th, .admin-form-table td {
    border: 1px solid #222222;
    background: #fff;
    padding: 5px;
    text-align: center;
}

.admin-form-table img {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
}

.admin-form-excel {
    font-size: 13px;
    color: #464032;
    display: block;
    width: 120px;
    margin-right: 5px;
}

#admin-form-excel-bottom {
    margin-top: 10px;
}

.admin-form-excel-link {
    text-decoration: none;
    color: #A7430F;
    font-size: 13px;
}

.admin-form-excel-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#admin-form-delete-col {
    width: 80px;
}

.admin-equ-add {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
}

.admin-equ-add div, .admin-equ-add span {    
    margin-top: 10px;
}

.admin-search-letter-dash {
    font-size: 13px;
    color: #464032;
}

.admin-search-letter {
    text-decoration: none;
    color: #A7430F;
    font-size: 13px;
    cursor: pointer;
}

.table-wrapper {
    overflow-x: scroll;
    width: 900px;
    max-height: 800px;
    overflow: scroll;
    margin-top: 15px;
}

.table-wrapper thead th{
    position: sticky;
    top: 0;
    background: #efeeee;
}

.table-wrapper-equ thead th{
    position: sticky;
    top: 0;
    background: #efeeee;
    z-index: 1;
}

.table-wrapper-equ {
    width: 100%;
    max-height: 400px;
    overflow: scroll;
}

.admin-patronyme {
    color: #A7430F;
    font-size: 13px;
    cursor: pointer;
}

.admin-bibli-button {
    margin-top: 20px;
}

#admin-bibli-table td {
    text-align: left !important;
}

#admin-bibli-table div {
    display: flex;
    flex-direction: row;
    padding: 5px;
    width: 400px;
}

.admin-label-bibli {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #464032;
    width: 110px;
}

.input-fil-transparent {
    color: transparent;
    margin: 0;
    width: 80px
}

.p-input-file {
    margin: 0;
    display: flex;
    align-items: center;
    max-width: 205px;
    line-break: anywhere;
}

#admin-bibli-table-file {
    display: flex;    
}

#heraldique-functions-container {
    border-top-right-radius: 0px;
}

.admin-form-areas-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80px;
    margin-bottom: 10px;
}

.admin-form-areas-input-container select{
    margin-top: 10px
}

.admin-form-areas-input-container-add {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80px;
    justify-content: space-between;
    margin-bottom: 10px;
}

.admin-form-areas-input-container label, .admin-form-areas-input-container-add label{
    margin: 0;
}

.admin-form-areas-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-img {
    position: relative; /* Ceci permet de positionner le pseudo-élément relativement à cet élément */
}

.admin-img {
    display: none;
}

.admin-img-container:hover .admin-img {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 5px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-img-container {
    position: relative;
    margin-bottom: 10px;
    width: min-content;
    height: min-content;
}

.admin-icono-new-image {
    margin-right: 20px;
}

.admin-book-img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.admin-book-new-img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.admin-imgs-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}